<!--
 * @Author: Jerry
 * @Date: 2021-01-27 09:33:10
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-06 17:18:25
 * @Description: file content
-->
<template>
  <div class="orderall">
    <order-title
      @changeCheck="changeCheck($event)"
      :isCheckedAll="true"
      :checkedAll="checkedAll"
    >
    </order-title>
    <div class="orderlists">
      <order-lists
        ref="orderLists"
        :dataSource="dataSource"
        @refreshData="refreshData"
        :hasCheckbox="true"
        @changeCheckAll="changeCheckAll($event)"
        name="开票管理"
      ></order-lists>
    </div>
    <div class="btnInvoice Ptp20 Pbm60">
      <el-button type="primary" @click="orderOpenInvoiceApply()"
        >全部开票</el-button
      >
    </div>
  </div>
</template>
<script>
import OrderLists from "@/components/myOrder/orderLists.vue";
import OrderTitle from "@/components/myOrder/orderTitle";

import { listMixin } from "@/mixins/listMixins";
import { getBuyerOpenInvoiceOrderPage } from "@/api/user";

import { mapGetters } from "vuex";
export default {
  mixins: [listMixin],
  components: { OrderTitle, OrderLists },
  data() {
    return {
      extraParam: {
        userId: "",
        orderStatus: "7",
      },
      url: {
        list: "",
      },
      checkedAll: false,
    };
  },
  computed: { 
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.extraParam.userId = this.userInfo.id;
    this.url.list = getBuyerOpenInvoiceOrderPage;
    this.loadData();
  },
  methods: {
    changeCheck(checkedAll) {
      this.checkedAll = checkedAll;
      let len = this.orderlists.length;
      if (this.checkedAll == true) {
        for (let i = 0; i < len; i++) {
          this.orderlists[i].checked = true;
        }
      }
      if (this.checkedAll == false) {
        for (let i = 0; i < len; i++) {
          this.orderlists[i].checked = false;
        }
      }
    },
    changeCheckAll(checkedAll) {
      this.checkedAll = !checkedAll;
      console.log(this.checkedAll);
    },
    orderOpenInvoiceApply() {
      let checkData = this.$refs.orderLists.getCheckData();
      if (this.validatenull(checkData)) {
        this.$message({
          type: 'waring',
          message: '至少选中一条数据！'
        })
        return false
      }
      this.$refs.orderLists.orderOpenInvoiceApply(checkData);
    }
  },
};
</script>
<style lang="scss" scoped>
.btnInvoice {
  display: flex;
  justify-content: flex-end;
}
</style>
